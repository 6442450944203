import React from 'react';
import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import PageIntro from '../components/page-intro';
import styled from 'styled-components';

const NotFoundPage = () => {
  return (
    <Layout title="404 Not Found">
      <PageHeader header>
        <h1>Oeps..</h1>
      </PageHeader>
      <PageIntro>
        <p>404 - Pagina niet gevonden</p>
      </PageIntro>
    </Layout>
  );
};

export default NotFoundPage;
