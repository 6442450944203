import styled from 'styled-components';

const PageIntro = styled.div`
  margin-top: var(--size-800);
  margin-bottom: var(--size-800);
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;

  & p {
    font-size: 24px;
    line-height: 30px;
  }
`;

export default PageIntro;
